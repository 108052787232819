import { type ReconcilerRoot } from '@react-three/fiber';
import { ContactShadows } from '@react-three/drei/core/ContactShadows';
import { Environment } from '@react-three/drei/core/Environment';
import { Suspense } from 'react';
import { suspend } from 'suspend-react';

import { type ReactThreeFiberApp } from '@/utils/react-three-fiber/common';

import { FrameloopController } from './FrameloopController';
import { WindowPointerSpringProvider } from './contexts/WindowPointer';
import { WindowResizeSpringProvider } from './contexts/WindowResize';
import { ScrollTimelineSpringProvider } from './contexts/ScrollTimeline';
import { Avatar } from './Avatar';
import { SceneCamera } from './SceneCamera';
import { Gadgets } from './Gadgets';

const hdr = import('@pmndrs/assets/hdri/sunset.exr');

export function HomeScene() {
  return (
    <Suspense>
      <FrameloopController />
      <WindowPointerSpringProvider>
        <WindowResizeSpringProvider>
          <ScrollTimelineSpringProvider>
            <ambientLight intensity={0.1} />
            <spotLight
              penumbra={1}
              position={[0, 0, 10]}
              intensity={0.75}
              shadow-bias={-0.0001}
              shadow-mapSize={256}
              castShadow
            />
            <pointLight position={[-1, -0.5, -0.25]} intensity={0.1} />
            <Suspense>
              <SceneCamera />
            </Suspense>
            <Suspense>
              <Avatar />
            </Suspense>
            <Suspense>
              <Gadgets />
            </Suspense>
            <Suspense>
              <ContactShadows
                position={[0, -0.4, 0]}
                scale={8}
                far={2}
                blur={2}
              />
            </Suspense>
            <Suspense>
              <Environment
                files={
                  suspend<string[], () => Promise<{ default: string }>>(hdr)
                    .default
                }
              />
            </Suspense>
          </ScrollTimelineSpringProvider>
        </WindowResizeSpringProvider>
      </WindowPointerSpringProvider>
    </Suspense>
  );
}

export const renderHomeScene: ReactThreeFiberApp = (
  root: ReconcilerRoot<HTMLCanvasElement | OffscreenCanvas>,
) => {
  root.render(<HomeScene />);
  return () => {};
};
