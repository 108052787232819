import { clamp, reverseLerp } from './maths';
import { type ProgrammaticStyleNames, type Tuple4 } from './types';

export function transform(x = 0, y = 0, w = 1, h = 1) {
  return `translate3d(${x}px,${y}px,0) scale(${w},${h})`;
}

export function computeInverse(
  [sx, sy, sw, sh]: Tuple4, // source
  [tx, ty, tw, th]: Tuple4, // target
): Tuple4 {
  // Transformation that makes the target look like the source
  return [
    sx - tx + (sw - tw) * 0.5,
    sy - ty + (sh - th) * 0.5,
    sw / tw,
    sh / th,
  ];
}

export function setStyle<
  T extends Partial<Record<ProgrammaticStyleNames, string>>,
>(element: HTMLElement, properties: T) {
  for (const name in properties) {
    const value = properties[name as ProgrammaticStyleNames];
    if (value) {
      if (/--.+/.test(name)) {
        element.style.setProperty(name, value);
      } else {
        element.style[name as ProgrammaticStyleNames] = value;
      }
    } else {
      element.style.removeProperty(name);
    }
  }
}

export function animation(
  element: Element,
  keyframes: Keyframe[] | PropertyIndexedKeyframes,
  options: KeyframeEffectOptions = {},
) {
  return new Animation(
    new KeyframeEffect(element, keyframes, {
      duration: 300,
      easing: 'ease-in-out',
      ...options,
    }),
  );
}

export function piecewise(from: number, to: number) {
  return (progress: number) => {
    return reverseLerp(clamp(progress, from, to), from, to);
  };
}
