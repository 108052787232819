import { to } from '@react-spring/core';
import { type Euler } from '@react-three/fiber';

import { AnimatedMacbookPro } from './models/AnimatedMacbookPro';
import { AnimatedAVP } from './models/AnimatedAVP';
import { AnimatedIPhonePro } from './models/AnimatedIPhonePro';
import { AnimatedAppleWatch } from './models/AnimatedAppleWatch';
import { AnimatedJoyCon } from './models/AnimatedJoyCon';
import { AnimatedOP1 } from './models/AnimatedOP1';
import { AnimatedESP32 } from './models/AnimatedESP32';
import { AnimatedPrusa } from './models/AnimatedPrusa';

import { useScrollTimelineSpringContext } from './contexts/ScrollTimeline';

export function Gadgets() {
  const scrollTimelineSpring = useScrollTimelineSpringContext();
  return (
    <group>
      <AnimatedJoyCon
        position={[0, 0.4, 2]}
        rotation={to<[number, number], Euler>(
          scrollTimelineSpring.xy,
          (_, y) => [
            Math.PI * 0.5 - y * 0.1,
            Math.PI * 0.625,
            Math.PI * 1.875 - y * 0.1,
          ],
        )}
      />
      <AnimatedOP1
        position={[-0.55, 1, 0.5]}
        rotation={to<[number, number], Euler>(
          scrollTimelineSpring.xy,
          (_, y) => [
            Math.PI * 0.5 - y * 0.1,
            Math.PI * 0.75,
            Math.PI * 1.875 - y * 0.1,
          ],
        )}
      />
      <AnimatedMacbookPro
        position={[0.5, 0.6, 1]}
        rotation={to<[number, number], Euler>(
          scrollTimelineSpring.xy,
          (_, y) => [
            Math.PI * 0.125 + y * 0.1,
            Math.PI,
            Math.PI * 0.0625 + y * 0.1,
          ],
        )}
      />
      <AnimatedAppleWatch
        position={[-0.125, 0.375, 2.5]}
        rotation={to<[number, number], Euler>(
          scrollTimelineSpring.xy,
          (_, y) => [Math.PI * 1.875 + y * 0.1, 0, Math.PI * 0.125 + y * 0.1],
        )}
      />
      <AnimatedIPhonePro
        position={[-0.225, 0.5, 2.5]}
        rotation={to<[number, number], Euler>(
          scrollTimelineSpring.xy,
          (_, y) => [
            Math.PI * 1.875 + y * 0.1,
            Math.PI,
            Math.PI * 0.125 + y * 0.1,
          ],
        )}
      />
      <AnimatedAVP
        position={[0.25, 0.5, 2.25]}
        rotation={to<[number, number], Euler>(
          scrollTimelineSpring.xy,
          (_, y) => [Math.PI * 0.05 + y * 1, Math.PI * -0.1 - y * 2, -y * 0.1],
        )}
      />
      <AnimatedESP32
        position={[-0.18, 0.65, 2.75]}
        rotation={
          to(scrollTimelineSpring.xy, (_, y) => [
            Math.PI * 0.5 + y * 0.05,
            Math.PI * 0.25,
            Math.PI * 1.75 + y * 0.05,
          ]) as unknown as Euler
        }
      />
      <AnimatedPrusa
        position={[-0.8, 4, -5]}
        rotation={
          to(scrollTimelineSpring.xy, (_, y) => [
            Math.PI * 1.75 + y * 0.1,
            Math.PI * 1.4375,
            Math.PI * 1.675 + y * 0.1,
          ]) as unknown as Euler
        }
      />
    </group>
  );
}
