import { useFrame } from '@react-three/fiber';

import { useIdle } from '@/components/react-three-fiber/hooks/useIdle';

function DisableRendering() {
  return useFrame(() => null, 1000);
}

let i = 0;
export function FrameloopController() {
  const isIdle = useIdle();
  console.debug('isIdle', isIdle, ++i);
  return isIdle ? <DisableRendering /> : null;
}
