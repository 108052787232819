import { type SpringValues, SpringValue, useSpring } from '@react-spring/core';
import { type ReactNode, createContext, useContext } from 'react';

import { measureWindowSize } from '@/utils/dom';
import { useEventListener } from '../hooks/useEventListener';

export type WindowResizeListener = (width: number, height: number) => void;

export type WindowResizeSpringState = { wh: [number, number] };

export type WindowResizeSpring = SpringValues<WindowResizeSpringState>;

function createInitialSpring(): WindowResizeSpringState {
  return { wh: measureWindowSize() };
}

const WindowResizeSpringContext = createContext<WindowResizeSpring>({
  wh: new SpringValue(),
});

export type WindowResizeSpringProviderProps = { children: ReactNode };

export function WindowResizeSpringProvider(
  props: WindowResizeSpringProviderProps,
) {
  const [spring, api] = useSpring(createInitialSpring);
  useEventListener(window, 'resize', () =>
    api.start({ wh: measureWindowSize() }),
  );
  return <WindowResizeSpringContext.Provider value={spring} {...props} />;
}

export function useWindowResizeSpringContext(): WindowResizeSpring {
  return useContext(WindowResizeSpringContext);
}
