import { createRoot } from '@react-three/fiber';

import { type ReactThreeFiberApp } from './common';
import { type WorkerOptions, takeOverCanvas } from './offscreen';

export function render(
  canvas: HTMLCanvasElement,
  app: ReactThreeFiberApp,
  options: WorkerOptions,
) {
  if (OffscreenCanvas === undefined) {
    const root = createRoot(canvas).configure({
      frameloop: 'demand',
      gl: { alpha: true, powerPreference: 'high-performance' },
      shadows: true,
    });
    const resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        if (entry.target === canvas) {
          const { x, y, width, height } = entry.contentRect;
          root.configure({
            size: {
              left: x,
              top: y,
              width: width,
              height: height,
              updateStyle: true,
            },
          });
          break;
        }
      }
    });
    resizeObserver.observe(canvas);
    const teardown = app(root, canvas);
    return () => {
      resizeObserver.disconnect();
      teardown();
      root.unmount();
    };
  }
  return takeOverCanvas(canvas, options);
}
