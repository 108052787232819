import { type PrimitiveProps } from '@react-three/fiber';
import { type PrusaModel, GLTFModel } from '@billykwok/3d';

import { AnimatedPrimitive } from '@/utils/react-three-fiber/animations';
import { useGLTF } from '@/utils/react-three-fiber/useGLTF';

const modelPath = `/models/${GLTFModel.Prusa}`;

export function AnimatedPrusa(props: Partial<PrimitiveProps>) {
  const { scene } = useGLTF<PrusaModel, string>(modelPath);
  return <AnimatedPrimitive object={scene} dispose={null} {...props} />;
}

useGLTF.preload(modelPath);
