import { useEffect } from 'react';
import { useEventEffect } from 'use-recapture';

export function useEventListener<
  T extends Window | Document | Element,
  K extends T extends Window ? keyof WindowEventMap : keyof DocumentEventMap,
  V extends K extends keyof WindowEventMap
    ? WindowEventMap[K]
    : K extends keyof DocumentEventMap
      ? DocumentEventMap[K]
      : K extends keyof ElementEventMap
        ? ElementEventMap[K]
        : Event,
>(
  target: T,
  event: K,
  onChange: (event: V) => void,
  options?: AddEventListenerOptions,
) {
  const listener = useEventEffect(onChange as EventListener);
  useEffect(() => {
    target.addEventListener(event, listener, options);
    return () =>
      target.removeEventListener(
        event,
        listener,
        options?.capture ? options : undefined,
      );
  }, []);
}
