export function clamp(value: number, min: number, max: number) {
  return Math.min(Math.max(value, min), max);
}

export function lerp(value: number, min: number, max: number) {
  return (1 - value) * min + value * max;
}

export function reverseLerp(value: number, min: number, max: number) {
  return (value - min) / (max - min);
}

export function outside(value: number, min: number, max: number) {
  return value <= min || value >= max;
}

export function distance(x: number, y: number, cx: number, cy: number) {
  return ((x - cx) ** 2 + (y - cy) ** 2) ** 0.5;
}
