import { PerspectiveCamera as DreiPerspectiveCamera } from '@react-three/drei/core/PerspectiveCamera';
import { applyProps } from '@react-three/fiber';
import { createHost } from '@react-spring/animated';
import { type ForwardRefExoticComponent, type FC, type JSX } from 'react';
import {
  type ComponentPropsWithRef,
  type ElementType,
} from '@react-spring/types';
import { Sphere } from '@react-three/drei';
import { type FluidValue } from '@react-spring/shared';

const { animated } = createHost(['primitive', 'mesh', 'group'], {
  // @ts-expect-error: TS2322 because of @react-three/fiber types design
  applyAnimatedValues: applyProps,
}) as { animated: WithAnimated };

export { animated };

export const AnimatedPrimitive = animated.primitive;
export const AnimatedMesh = animated.mesh;
export const AnimatedGroup = animated.group;
export const AnimatedSphere = animated(Sphere);
export const AnimatedPerspectiveCamera = animated(DreiPerspectiveCamera);

type SupportedIntrinsicElements = Pick<
  JSX.IntrinsicElements,
  'primitive' | 'mesh' | 'group'
>;
export type SupportedPrimitives = keyof SupportedIntrinsicElements;

/** The type of the `animated()` function */
export type WithAnimated = (<T extends ElementType>(
  wrappedComponent: T,
) => AnimatedComponent<T>) & {
  [P in SupportedPrimitives]: AnimatedComponent<
    FC<SupportedIntrinsicElements[P]>
  >;
};

/** The type of an `animated()` component */
export type AnimatedComponent<T extends ElementType> =
  ForwardRefExoticComponent<AnimatedProps<ComponentPropsWithRef<T>>>;

/** The props of an `animated()` component */
export type AnimatedProps<Props extends Record<string, unknown>> = {
  [P in keyof Props]: P extends 'ref' | 'key'
    ? Props[P]
    : AnimatedProperty<Props[P]>;
};

// The animated prop value of a React element
type AnimatedProperty<T> = T extends never
  ? never
  : T extends undefined | null
    ? T
    : T | AnimatedLeaf<T>;

// An animated primitive (or an array of them)
type AnimatedLeaf<T> =
  | Exclude<T, object | undefined>
  | Extract<T, ReadonlyArray<number | string | undefined>> extends infer U
  ? U extends never
    ? never
    : FluidValue<U | Exclude<T, object | undefined>>
  : never;
