import { useRef, useState } from 'react';

import { useEventListener } from './useEventListener';
import { useViewportIntersection } from './useViewportIntersection';

export type IdleStateCallback = (isIdle: boolean) => void;

export function useIdle() {
  const [idleState, setIdleState] = useState(true);
  const isHovered = useRef(false);
  const isOffscreen = useRef(false);
  useViewportIntersection((entry) =>
    setIdleState((isOffscreen.current = !entry.isIntersecting)),
  );
  useEventListener(document, 'visibilitychange', () => {
    if (document.visibilityState === 'hidden') {
      setIdleState(true);
    }
  });
  useEventListener(
    document.documentElement,
    'pointerleave',
    () => (isHovered.current = false),
  );
  useEventListener(document.documentElement, 'pointerenter', () => {
    if (!isOffscreen.current) {
      isHovered.current = true;
      setIdleState(false);
    }
  });
  useEventListener(window, 'pointermove', () => {
    if (!isOffscreen.current) {
      isHovered.current = true;
      setIdleState(false);
    }
  });
  useEventListener(
    document,
    'scroll',
    () => {
      if (!isOffscreen.current) {
        setIdleState(false);
      }
    },
    { passive: true },
  );
  return idleState;
}
