import { useEffect } from 'react';

import {
  type OnIntersect,
  observeViewportIntersection,
  unobserveViewportIntersection,
} from '@/utils/observers';

export function useViewportIntersection(cb: OnIntersect) {
  return useEffect(() => {
    const canvas = document.querySelector('#sticky-parent')!;
    observeViewportIntersection(canvas, cb);
    return () => unobserveViewportIntersection(canvas, cb);
  });
}
