import {
  type SpringValues,
  SpringRef,
  SpringValue,
  useSpring,
} from '@react-spring/core';
import { type ReactNode, createContext, useContext } from 'react';

import { measureWindowSize } from '@/utils/dom';
import { useEventListener } from '../hooks/useEventListener';

export type WindowPointerListener = (x: number, y: number) => void;

export type WindowPointerSpringState = Readonly<{ xy: [number, number] }>;

export type WindowPointerSpring = SpringValues<WindowPointerSpringState>;

export type WindowPointerApi = SpringRef<WindowPointerSpringState>;

function createInitialSpring(): WindowPointerSpringState {
  const [w, h] = measureWindowSize();
  return { xy: [w * 0.5, h * 0.5] };
}

const WindowPointerSpringContext = createContext<WindowPointerSpring>({
  xy: new SpringValue(),
});

const WindowPointerApiContext = createContext<WindowPointerApi>(SpringRef());

export type WindowPointerSpringProviderProps = { children: ReactNode };

export function WindowPointerSpringProvider({
  children,
}: WindowPointerSpringProviderProps) {
  const [spring, api] = useSpring(createInitialSpring);
  useEventListener(
    window,
    'pointermove',
    ({ isPrimary, clientX: x, clientY: y }: PointerEvent) => {
      if (isPrimary) {
        void api.start({ xy: [x, y] });
      }
    },
  );
  return (
    <WindowPointerSpringContext.Provider value={spring}>
      <WindowPointerApiContext.Provider value={api}>
        {children}
      </WindowPointerApiContext.Provider>
    </WindowPointerSpringContext.Provider>
  );
}

export function useWindowPointerSpringContext(): [
  WindowPointerSpring,
  WindowPointerApi,
] {
  return [
    useContext(WindowPointerSpringContext),
    useContext(WindowPointerApiContext),
  ];
}
