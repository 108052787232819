import { type Vector3 } from '@react-three/fiber';
import { to } from '@react-spring/core';

import { useScrollTimelineSpringContext } from '@/components/react-three-fiber/contexts/ScrollTimeline';
import { piecewise } from '@/utils/animations';
import { AnimatedPerspectiveCamera } from '@/utils/react-three-fiber/animations';
import { clamp } from '@/utils/maths';

const moveUp = piecewise(0.05, 0.8);

export function SceneCamera() {
  const scrollTimelineSpring = useScrollTimelineSpringContext();
  return (
    <AnimatedPerspectiveCamera
      position={to<[number, number], Vector3>(
        scrollTimelineSpring.xy,
        (_, y) => [0, clamp(moveUp(y) * 2, 0, 1.75), 4],
      )}
      zoom={3.25}
      makeDefault
    />
  );
}
