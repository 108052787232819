import {
  type ReactThreeFiberApp,
  setUpReactThreeFiber,
} from '@/utils/react-three-fiber/common';
import { render } from '@/utils/react-three-fiber/render';
import { type WorkerOptions } from './offscreen';

if (OffscreenCanvas === undefined) {
  setUpReactThreeFiber();
}

export class ReactThreeFiberCanvas extends HTMLCanvasElement {
  #teardown: (() => void) | undefined;
  #app: ReactThreeFiberApp;
  #options: WorkerOptions;

  constructor(app: ReactThreeFiberApp, options: WorkerOptions) {
    super();
    this.#app = app;
    this.#options = options;
  }

  async connectedCallback() {
    if (this.#teardown) {
      this.#teardown();
    }

    const teardown = await render(this, this.#app, this.#options);
    this.#teardown = () => {
      try {
        teardown();
      } catch (e) {
        console.error(e);
      }
    };
  }

  disconnectedCallback() {
    if (this.#teardown) {
      this.#teardown();
      this.#teardown = undefined;
    }
  }
}
