import { type SerializedDOMRect } from '@/workers/react-three-fiber/types/messages';
import { measureSerializedBoundingClientRects } from '@/utils/dom';

export type InitialProps = {
  window: {
    size: [width: number, height: number];
    scroll: [x: number, y: number];
  };
  documentElement: {
    xy: [x: number, y: number];
    size: [width: number, height: number];
  };
  trackedRects: Record<string, SerializedDOMRect>;
};

export function hydrateEnvironmentState(
  name: string,
): [
  basename: string,
  windowSize: [number, number],
  windowScroll: [number, number],
  documentElementXY: [number, number],
  documentElementSize: [number, number],
  trackedRects: Map<string, DOMRect>,
] {
  const parsed = /^(.+)-({.+})$/.exec(name);
  if (!parsed) {
    throw new Error(`Invalid name ${name}`);
  }
  const [, basename, json] = parsed;
  const {
    window: { size: windowSize, scroll: windowScroll },
    documentElement: { xy: documentElementXY, size: documentElementSize },
    trackedRects,
  } = JSON.parse(json) as InitialProps;
  return [
    basename,
    windowSize,
    windowScroll,
    documentElementXY,
    documentElementSize,
    new Map(
      Object.entries(trackedRects).map<[string, DOMRect]>(
        ([selector, rect]) => [selector, DOMRect.fromRect(rect)],
      ),
    ),
  ];
}

export function serializeWindowState(
  name: string,
  windowSize: [number, number],
  windowScroll: [number, number],
  documentElementSize: [number, number],
  documentElementXY: [number, number],
  trackedElements: Record<string, Element>,
) {
  const state: InitialProps = {
    window: { size: windowSize, scroll: windowScroll },
    documentElement: { xy: documentElementXY, size: documentElementSize },
    trackedRects: measureSerializedBoundingClientRects(trackedElements),
  };
  return `${name}-${JSON.stringify(state)}`;
}
