import { type Mesh } from 'three';
import { to, useSpring } from '@react-spring/core';
import { type GroupProps, type Vector3 } from '@react-three/fiber';
import { useEffect, useMemo } from 'react';
import { type AvatarModel, GLTFModel } from '@billykwok/3d';
import { useEnvironment } from '@react-three/drei/core/useEnvironment';

import { useGLTF } from '@/utils/react-three-fiber/useGLTF';
import { clamp, distance, outside } from '@/utils/maths';
import { delay } from '@/utils/promises';
import { measureWindowSize } from '@/utils/dom';
import {
  AnimatedGroup,
  AnimatedMesh,
} from '@/utils/react-three-fiber/animations';

import { useWindowPointerSpringContext } from './contexts/WindowPointer';
import { useWindowResizeSpringContext } from './contexts/WindowResize';
import { useEventListener } from './hooks/useEventListener';
import { useScrollTimelineSpringContext } from './contexts/ScrollTimeline';
import { piecewise } from '@/utils/animations';
import { AnimatedEasel } from './models/AnimatedEasel';
import { suspend } from 'suspend-react';

const GAZE_TARGET = { x: -0.25, y: -0.5, r: 300 };

const modelPath = `/models/${GLTFModel.Avatar}`;

function influencesOf(springValues: Record<string, unknown>, mesh: Mesh) {
  const dictionary = mesh.morphTargetDictionary;
  console.log(mesh.morphTargetDictionary);
  if (!dictionary) {
    return;
  }
  const entries = Object.entries(dictionary);
  const influences: number[] = Array.from({ length: entries.length });
  for (const [name, index] of entries) {
    if (!(name in springValues)) {
      throw new Error(`Unknown morph target: ${name}`);
    }
    influences[index] = springValues[name] as number;
  }
  return to(influences, (...values) => values) as unknown as number[];
}

type BlinkState = Readonly<{ influence: number }>;
type WaveState = Readonly<{ rad: number }>;

const lookUp = piecewise(0.01, 0.05);
const moveInEasel = piecewise(0.65, 0.75);
const moveInEasel2 = piecewise(0.665, 0.8);
const scaleEasel = piecewise(0.8, 0.9);

const hdrDawn = import('@pmndrs/assets/hdri/dawn.exr');

export function Avatar(_props: GroupProps) {
  const texture = useEnvironment({
    files: suspend<string[], () => Promise<{ default: string }>>(hdrDawn)
      .default,
  });
  const { nodes, materials } = useGLTF<AvatarModel, string>(modelPath);
  useEffect(() => {
    for (const material of Object.values(materials)) {
      material.envMap = texture;
    }
  });
  const [windowPointerSpring, windowPointerApi] =
    useWindowPointerSpringContext();
  const windowResizeSpring = useWindowResizeSpringContext();
  const scrollTimelineSpring = useScrollTimelineSpringContext();
  useEventListener(window, 'resize', () => {
    const [w, h] = measureWindowSize();
    windowPointerApi.start({ xy: [w * 0.5, h * 0.5] });
  });
  useEventListener(
    document.documentElement,
    'pointerleave',
    ({ isPrimary, clientX: x, clientY: y }) => {
      if (isPrimary) {
        const [w, h] = measureWindowSize();
        if (outside(x, 0, w) || outside(y, 0, h)) {
          void windowPointerApi.start({ xy: [w * 0.5, h * 0.5] });
        }
      }
    },
  );
  const targetSpring = useMemo(
    () =>
      to(
        [windowPointerSpring.xy, windowResizeSpring.wh],
        ([x, y], [w, h]): [x: number, y: number, r: number] => {
          if (!w || !h) {
            return [0, 0, 0];
          }
          const [wHalf, hHalf] = [w * 0.5, h * 0.5];
          return [1 - x / wHalf, 1 - y / hHalf, distance(x, y, wHalf, hHalf)];
        },
      ),
    [windowPointerSpring, windowResizeSpring],
  );
  const [blinkSpring] = useSpring<BlinkState>(() => ({
    loop: true,
    config: { tension: 100, friction: 1, clamp: true },
    from: { influence: 0 },
    to: async (next) => {
      await delay(2000);
      await next({ influence: 1 });
      await next({ influence: 0 });
      await delay(1000);
    },
  }));
  const [waveSpring] = useSpring<WaveState>(() => ({
    loop: true,
    config: { tension: 100, friction: 1, clamp: true },
    from: { rad: -Math.PI * 0.125 },
    to: async (next) => {
      await delay(3000);
      const [x, y] = windowPointerSpring.xy.get();
      const [w, h] = windowResizeSpring.wh.get();
      if (distance(x, y, w * 0.5, h * 0.5) > 300) {
        await next({ rad: Math.PI * -0.125 });
        await delay(2000);
        return;
      }
      await next({ rad: Math.PI * -0.25 });
      if (distance(x, y, w * 0.5, h * 0.5) > 300) {
        await next({ rad: Math.PI * -0.125 });
        await delay(2000);
        return;
      }
      await next({ rad: Math.PI * 0.125 });
      if (distance(x, y, w * 0.5, h * 0.5) > 300) {
        await next({ rad: Math.PI * -0.125 });
        await delay(2000);
        return;
      }
      await next({ rad: Math.PI * -0.25 });
      await next({ rad: Math.PI * -0.125 });
      await delay(2000);
    },
  }));
  const transformationSpring = useMemo(
    () => ({
      Head: {
        position: [-0.06, -0.1, 0] as Vector3,
        rotation: to(
          [targetSpring, scrollTimelineSpring.xy],
          ([x, y], [_sx, sy]) => {
            const p = lookUp(sy);
            return [
              -0.1 + (1 - p) * y * -Math.PI * 0.125 - p * 0.5,
              -0.16 + (1 - p) * x * -Math.PI * 0.25,
              0,
            ];
          },
        ),
      },
      HandLeft: {
        position: to(
          [targetSpring, scrollTimelineSpring.xy],
          ([x, y], [_sx, sy]) => {
            const p = lookUp(sy);
            const p2 = moveInEasel(sy);
            const p3 = scaleEasel(sy);
            return sy > 0.65
              ? [
                  -0.161 - 0.25 * (1 - p2) - 0.125 * p3,
                  0.45 + 1 * p2 - 0.125 * p3,
                  0.21 + 4 - 4 * p2,
                ]
              : [
                  0.08 - (1 - p) * x * 0.0125,
                  -0.27,
                  0.15 - (1 - p) * y * 0.0125,
                ];
          },
        ),
        rotation: to(
          [targetSpring, waveSpring.rad, scrollTimelineSpring.xy],
          ([x], value, [_, sy]) => {
            const p = lookUp(sy);
            return sy > 0.65
              ? [0, Math.PI * 0.75, Math.PI * -0.25]
              : [0, (Math.PI * -0.5 - x * (1 - p)) * 0.25, value * (1 - p)];
          },
        ),
      },
      HandRight: {
        position: to(
          [targetSpring, scrollTimelineSpring.xy],
          ([x, y], [_sx, sy]) => {
            const p = lookUp(sy);
            const p2 = moveInEasel2(sy);
            const p3 = scaleEasel(sy);
            return p2 > 0
              ? [
                  0.335 - 0.2 * Math.sin(p2) + 0.125 * p3,
                  2.125 - 0.25 * p2 + 0.125 * p3,
                  0.1 + 4 - 4 * p2,
                ]
              : [
                  -0.2 - (1 - p) * x * 0.0125,
                  -0.24,
                  0.1 - (1 - p) * y * 0.0125,
                ];
          },
        ),
        rotation: to(
          [targetSpring, scrollTimelineSpring.xy],
          ([x], [_, sy]) => {
            const p = lookUp(sy);
            return sy > 0.65
              ? [-0.375, Math.PI * -0.875, Math.PI * -0.125]
              : [
                  -Math.PI * 0.25,
                  -0.25 + (Math.PI - x * (1 - p)) * 0.125,
                  Math.PI * 0.5,
                ];
          },
        ),
      },
      Easel: {
        position: to([scrollTimelineSpring.xy], ([_, sy]) => {
          const p = moveInEasel(sy);
          const p3 = scaleEasel(sy);
          return [
            -0.1 - 0.25 * (1 - p) + 0.1 * p3,
            0.5 + 1 * p - 1.16 * p3,
            4 - 4 * p,
          ];
        }),
        rotation: to([scrollTimelineSpring.xy], ([_, sy]) => {
          const p = moveInEasel(sy);
          const p3 = scaleEasel(sy);
          return [0.05 + 0.125 * (1 - p3), Math.PI * 0.5 * (1 - p3), 0];
        }),
        scale: to([scrollTimelineSpring.xy], ([_, sy]) => {
          const p = scaleEasel(sy);
          return 0.3 + 0.5 * p;
        }),
      },
    }),
    [targetSpring, waveSpring],
  );
  const morphTargetSpring = useMemo(
    () => ({
      LookUp: to(
        [targetSpring, scrollTimelineSpring.xy],
        ([, y, r]: [number, number, number], [_sx, sy]) => {
          const p = lookUp(sy);
          const isLookingUp = p > 0;
          return clamp(
            isLookingUp
              ? p * 0.5
              : GAZE_TARGET.y + (r <= GAZE_TARGET.r ? -y : y),
            0,
            1,
          );
        },
      ),
      LookDown: to(
        [targetSpring, scrollTimelineSpring.xy],
        ([, y, r], [_sx, sy]) => {
          const p = lookUp(sy);
          const isLookingUp = p > 0;
          return clamp(
            isLookingUp
              ? lookUp(sy) * -0.5
              : -GAZE_TARGET.y - (r <= GAZE_TARGET.r ? -y : y),
            0,
            1,
          );
        },
      ),
      LookLeft: to(
        [targetSpring, scrollTimelineSpring.xy],
        ([x, , r], [_sx, sy]) => {
          const p = lookUp(sy);
          const isLookingUp = p > 0;
          return isLookingUp
            ? 0
            : clamp(
                -GAZE_TARGET.x - (r <= GAZE_TARGET.r ? -x : 1.5 * x + 1),
                0,
                1,
              );
        },
      ),
      LookRight: to(
        [targetSpring, scrollTimelineSpring.xy],
        ([x, , r], [_sx, sy]) => {
          const p = lookUp(sy);
          const isLookingUp = p > 0;
          return isLookingUp
            ? 0
            : clamp(
                GAZE_TARGET.x + (r <= GAZE_TARGET.r ? -x : 1.5 * x + 1),
                0,
                1,
              );
        },
      ),
      Smile: to([targetSpring, scrollTimelineSpring.xy], ([x, y], [_sx, sy]) =>
        clamp(
          0.6 + Math.abs(x * 0.2) + Math.abs(y * 0.2) + lookUp(sy) * 0.5,
          0,
          1,
        ),
      ),
      RaiseEyebrows: to(
        [targetSpring, scrollTimelineSpring.xy],
        ([x, y], [_sx, sy]) =>
          clamp(Math.abs(x * 0.5) + Math.abs(y * 0.5) + lookUp(sy) * 0.5, 0, 1),
      ),
      CloseEyes: to([blinkSpring.influence], (value) => clamp(value, 0, 1)),
      PinchLeft: to(scrollTimelineSpring.xy, (_, y) => {
        const p = moveInEasel(y);
        return y > 0.65 ? clamp(p, 0.8, 1) : 0;
      }),
      PinchRight: to(scrollTimelineSpring.xy, (_, y) => {
        const p = moveInEasel(y);
        return y > 0.65 ? clamp(p, 0.8, 1) : 0;
      }),
    }),
    [targetSpring, blinkSpring],
  );
  const influences = useMemo(
    () => ({
      HandLeft: influencesOf(morphTargetSpring, nodes.Hand_L),
      HandRight: influencesOf(morphTargetSpring, nodes.Hand_R),
      Eyebrows: influencesOf(morphTargetSpring, nodes.Eyebrows),
      Eyelashes: influencesOf(morphTargetSpring, nodes.Eyelashes),
      Tongue: influencesOf(morphTargetSpring, nodes.Tongue),
      Eyes: influencesOf(morphTargetSpring, nodes.mesh_eyes),
      Iris: influencesOf(morphTargetSpring, nodes.mesh_eyes_1),
      Pupils: influencesOf(morphTargetSpring, nodes.mesh_eyes_2),
      Head: influencesOf(morphTargetSpring, nodes.mesh_head),
      Lips: influencesOf(morphTargetSpring, nodes.mesh_head_1),
      Gums: influencesOf(morphTargetSpring, nodes.mesh_head_2),
      TeethLower: influencesOf(morphTargetSpring, nodes.mesh_teeth_lower),
      TeethGumLower: influencesOf(morphTargetSpring, nodes.mesh_teeth_lower_1),
      TeethUpper: influencesOf(morphTargetSpring, nodes.mesh_teeth_upper),
      TeethGumUpper: influencesOf(morphTargetSpring, nodes.mesh_teeth_upper_1),
    }),
    [nodes, morphTargetSpring],
  );
  /* eslint-disable react/no-unknown-property */

  console.log(nodes.Hand_L.morphTargetDictionary);
  console.log(nodes.Hand_R.morphTargetDictionary);

  return (
    <group>
      <AnimatedEasel
        position={transformationSpring.Easel.position}
        rotation={transformationSpring.Easel.rotation}
        scale={transformationSpring.Easel.scale}
      />
      <AnimatedMesh
        material={materials.PaletteMaterial001}
        geometry={nodes.Hand_L.geometry}
        position={transformationSpring.HandLeft.position}
        rotation={transformationSpring.HandLeft.rotation}
        morphTargetDictionary={nodes.Hand_L.morphTargetDictionary}
        morphTargetInfluences={influences.HandLeft}
      />
      <AnimatedMesh
        material={materials.PaletteMaterial001}
        geometry={nodes.Hand_R.geometry}
        position={transformationSpring.HandRight.position}
        rotation={transformationSpring.HandRight.rotation}
        morphTargetDictionary={nodes.Hand_R.morphTargetDictionary}
        morphTargetInfluences={influences.HandRight}
      />
      <AnimatedGroup
        position={transformationSpring.Head.position}
        rotation={transformationSpring.Head.rotation}
      >
        <mesh
          material={materials.PaletteMaterial001}
          geometry={nodes.Ears.geometry}
        />
        <mesh
          material={materials.PaletteMaterial002}
          geometry={nodes.Hair.geometry}
        />
        <AnimatedMesh
          material={materials.PaletteMaterial002}
          geometry={nodes.Eyebrows.geometry}
          morphTargetDictionary={nodes.Eyebrows.morphTargetDictionary}
          morphTargetInfluences={influences.Eyebrows}
        />
        <AnimatedMesh
          material={materials.PaletteMaterial002}
          geometry={nodes.Eyelashes.geometry}
          morphTargetDictionary={nodes.Eyelashes.morphTargetDictionary}
          morphTargetInfluences={influences.Eyelashes}
        />
        <AnimatedMesh
          material={materials.PaletteMaterial005}
          geometry={nodes.Tongue.geometry}
          morphTargetDictionary={nodes.Tongue.morphTargetDictionary}
          morphTargetInfluences={influences.Tongue}
        />
        <AnimatedMesh
          material={materials.PaletteMaterial003}
          geometry={nodes.mesh_eyes.geometry}
          morphTargetDictionary={nodes.mesh_eyes.morphTargetDictionary}
          morphTargetInfluences={influences.Eyes}
        />
        <AnimatedMesh
          material={materials.PaletteMaterial004}
          geometry={nodes.mesh_eyes_1.geometry}
          morphTargetDictionary={nodes.mesh_eyes_1.morphTargetDictionary}
          morphTargetInfluences={influences.Iris}
        />
        <AnimatedMesh
          material={materials.PaletteMaterial004}
          geometry={nodes.mesh_eyes_2.geometry}
          morphTargetDictionary={nodes.mesh_eyes_2.morphTargetDictionary}
          morphTargetInfluences={influences.Pupils}
        />
        <AnimatedMesh
          material={materials.PaletteMaterial001}
          geometry={nodes.mesh_head.geometry}
          morphTargetDictionary={nodes.mesh_head.morphTargetDictionary}
          morphTargetInfluences={influences.Head}
        />
        <AnimatedMesh
          material={materials.mat_lips}
          geometry={nodes.mesh_head_1.geometry}
          morphTargetDictionary={nodes.mesh_head_1.morphTargetDictionary}
          morphTargetInfluences={influences.Lips}
        />
        <AnimatedMesh
          material={materials.PaletteMaterial005}
          geometry={nodes.mesh_head_2.geometry}
          morphTargetDictionary={nodes.mesh_head_2.morphTargetDictionary}
          morphTargetInfluences={influences.Gums}
        />
        <AnimatedMesh
          material={materials.PaletteMaterial005}
          geometry={nodes.mesh_teeth_lower.geometry}
          morphTargetDictionary={nodes.mesh_teeth_lower.morphTargetDictionary}
          morphTargetInfluences={influences.TeethLower}
        />
        <AnimatedMesh
          material={materials.PaletteMaterial005}
          geometry={nodes.mesh_teeth_lower_1.geometry}
          morphTargetDictionary={nodes.mesh_teeth_lower_1.morphTargetDictionary}
          morphTargetInfluences={influences.TeethGumLower}
        />
        <AnimatedMesh
          material={materials.PaletteMaterial005}
          geometry={nodes.mesh_teeth_upper.geometry}
          morphTargetDictionary={nodes.mesh_teeth_upper.morphTargetDictionary}
          morphTargetInfluences={influences.TeethUpper}
        />
        <AnimatedMesh
          material={materials.PaletteMaterial005}
          geometry={nodes.mesh_teeth_upper_1.geometry}
          morphTargetDictionary={nodes.mesh_teeth_upper_1.morphTargetDictionary}
          morphTargetInfluences={influences.TeethGumUpper}
        />
      </AnimatedGroup>
    </group>
  );
  /* eslint-enable react/no-unknown-property */
}

useGLTF.preload(modelPath);
