import { type PrimitiveProps } from '@react-three/fiber';
import { type ESP32Model, GLTFModel } from '@billykwok/3d';

import { AnimatedPrimitive } from '@/utils/react-three-fiber/animations';
import { useGLTF } from '@/utils/react-three-fiber/useGLTF';

const modelPath = `/models/${GLTFModel.ESP32}`;

export function AnimatedESP32(props: Partial<PrimitiveProps>) {
  const { scene } = useGLTF<ESP32Model, string>(modelPath);
  return <AnimatedPrimitive object={scene} dispose={null} {...props} />;
}

useGLTF.preload(modelPath);
