import { type SerializedDOMRect } from '@/workers/react-three-fiber/types/messages';
import { type Tuple4 } from './types';

export function el<T extends keyof HTMLElementTagNameMap>(
  tag: T,
  props: Partial<HTMLElementTagNameMap[T]>,
  ...children: Array<string | Node>
) {
  const element = Object.assign(document.createElement(tag), props);
  element.append(...children);
  return element;
}

export function measureSerializedBoundingClientRects(
  trackedElements: Record<string, Element>,
) {
  return Object.entries(trackedElements).reduce<
    Record<string, SerializedDOMRect>
  >((acc, [selector, element]) => {
    acc[selector] = element
      .getBoundingClientRect()
      .toJSON() as SerializedDOMRect;
    return acc;
  }, {});
}

export function measureWindowSize(): [width: number, height: number] {
  const { innerWidth: w, innerHeight: h } = window;
  return [w, h];
}

export function measureWindowScroll(): [x: number, y: number] {
  const { scrollX: x, scrollY: y } = window;
  return [x, y];
}

export function measureDocumentElementXY(): [x: number, y: number] {
  const {
    body: {
      scrollLeft,
      offsetLeft,
      clientLeft,
      scrollTop,
      offsetTop,
      clientTop,
    },
    documentElement: {
      scrollLeft: docElScrollLeft,
      offsetLeft: docElOffsetLeft,
      clientLeft: docElClientLeft,
      scrollTop: docElScrollTop,
      offsetTop: docElOffsetTop,
      clientTop: docElClientTop,
    },
  } = document;
  return [
    Math.max(
      scrollLeft,
      offsetLeft,
      clientLeft,
      docElScrollLeft,
      docElOffsetLeft,
      docElClientLeft,
    ),
    Math.max(
      scrollTop,
      offsetTop,
      clientTop,
      docElScrollTop,
      docElOffsetTop,
      docElClientTop,
    ),
  ];
}

export function measureDocumentElementSize(): [width: number, height: number] {
  const {
    body: {
      scrollWidth,
      offsetWidth,
      clientWidth,
      scrollHeight,
      offsetHeight,
      clientHeight,
    },
    documentElement: {
      scrollWidth: docElScrollWidth,
      offsetWidth: docElOffsetWidth,
      clientWidth: docElClientWidth,
      scrollHeight: docElScrollHeight,
      offsetHeight: docElOffsetHeight,
      clientHeight: docElClientHeight,
    },
  } = document;
  return [
    Math.max(
      scrollWidth,
      offsetWidth,
      clientWidth,
      docElScrollWidth,
      docElOffsetWidth,
      docElClientWidth,
    ),
    Math.max(
      scrollHeight,
      offsetHeight,
      clientHeight,
      docElScrollHeight,
      docElOffsetHeight,
      docElClientHeight,
    ),
  ];
}

export function measure(element: Element): Tuple4 {
  const { x, y, width, height } = element.getBoundingClientRect();
  return [x, y, width, height];
}
