import {
  type EventUpdate,
  type IntersectionObserverUpdate,
  type MainThreadBoundMessage,
  type Message,
  type ResizeObserverUpdate,
  type SerializedDOMRect,
  type SerializedMouseEvent,
  type SerializedPointerEvent,
  type SerializedResizeEvent,
  type SerializedScrollEvent,
  type SerializedVisibilityChangeEvent,
  type SerializedWheelEvent,
  type TransferContent,
} from '@/workers/react-three-fiber/types/messages';
import {
  type EventSubscription,
  type IntersectionObserverLifecycleSubscription,
  type IntersectionObserverSubscription,
  type ResizeObserverLifecycleSubscription,
  type ResizeObserverSubscription,
} from '@/workers/react-three-fiber/types/subscriptions';
import { type HydratedEventInit } from '@/workers/react-three-fiber/emulations/events';
import { serializeWindowState } from '@/workers/react-three-fiber/utils/common';
import { UnsupportedError } from '@/utils/errors';

import {
  measureDocumentElementSize,
  measureDocumentElementXY,
  measureSerializedBoundingClientRects,
  measureWindowScroll,
  measureWindowSize,
} from '../dom';

const intersectionObservers = new Map<string, IntersectionObserver>();
const resizeObservers = new Map<string, ResizeObserver>();

export type WorkerOptions = {
  url: URL;
  basename: string;
  trackedRects?: string[];
};

type SerializableContext = {
  canvas: HTMLCanvasElement;
  trackedElements: Record<string, Element>;
};

function serializeNullableEventTargetInMainThread(
  context: SerializableContext,
  target: EventTarget | null,
) {
  if (target === self.window) {
    return 'window';
  }
  if (target === self.document) {
    return 'document';
  }
  if (target === self.document.documentElement) {
    return ':root';
  }
  if (target === context.canvas) {
    return 'canvas';
  }
  return null;
}

function serializeEventTargetInMainThread(
  context: SerializableContext,
  target: EventTarget | null,
) {
  const result = serializeNullableEventTargetInMainThread(context, target);
  if (result === null) {
    throw new UnsupportedError(`Target ${String(target)}`);
  }
  return result;
}

function serializeObserverTargetInMainThread(
  context: SerializableContext,
  target: EventTarget,
) {
  if (target === self.document.documentElement) {
    return ':root';
  }
  if (target === context.canvas) {
    return 'canvas';
  }
  for (const [selector, element] of Object.entries(context.trackedElements)) {
    if (element === target) {
      return selector;
    }
  }
  throw new UnsupportedError(`Target ${String(target)}`);
}

export function takeOverCanvas(
  canvas: HTMLCanvasElement,
  { url, basename, trackedRects = [] }: WorkerOptions,
) {
  const trackedElements = trackedRects.reduce<Record<string, Element>>(
    (acc, selector) => {
      const el = document.querySelector(selector);
      if (!el) {
        throw new Error(`Element with selector ${selector} not found`);
      }
      acc[selector] = el;
      return acc;
    },
    {},
  );
  const name = serializeWindowState(
    basename,
    measureWindowSize(),
    measureWindowScroll(),
    measureDocumentElementSize(),
    measureDocumentElementXY(),
    trackedElements,
  );
  const worker = new Worker(url, { type: 'module', name });
  const prefix = `[webworker(${basename})]`;
  const context: SerializableContext = { canvas, trackedElements };

  function domEventListener(e: Event) {
    if (!e.target) {
      console.warn(prefix, 'Event requires target but got null', e);
      return;
    }
    worker.postMessage({
      type: 'event',
      content: serializeEvent(context, e),
    } satisfies Message<'event', EventUpdate>);
  }
  function nonPassiveEventListener(e: Event) {
    e.preventDefault();
    domEventListener(e);
  }
  function pointerDownEventListener(e: PointerEvent) {
    const { target, pointerId } = e;
    (target as Element).setPointerCapture(pointerId);
    domEventListener(e);
  }
  function pointerUpEventListener(e: PointerEvent) {
    const { target, pointerId } = e;
    (target as Element).releasePointerCapture(pointerId);
    domEventListener(e);
  }
  function resolveEventListenerAndOptions({
    type,
    target,
    options,
  }: EventSubscription): [EventListener, boolean | AddEventListenerOptions] {
    let _options: AddEventListenerOptions = {
      passive: false,
      ...(options && typeof options === 'boolean'
        ? { capture: options }
        : options),
    };
    if (type === 'wheel' || type === 'scroll') {
      _options.passive = true;
    }
    if (!_options.passive) {
      return [nonPassiveEventListener, _options];
    }
    if (target === 'canvas') {
      if (type === 'pointerdown') {
        return [pointerDownEventListener as EventListener, _options];
      }
      if (type === 'pointerup') {
        return [pointerUpEventListener as EventListener, _options];
      }
    }
    return [domEventListener, _options];
  }

  function errorHandler(event: ErrorEvent | MessageEvent) {
    console.error(prefix, 'An error was thrown in worker', event);
  }
  function messageHandler(event: MessageEvent<MainThreadBoundMessage>) {
    const { type, content } = event.data;
    console.debug(prefix, 'Received message', event.data);
    switch (type) {
      case 'event': {
        const target = hydrateEventTarget(context, content.target);
        if (!target) {
          throw new UnsupportedError(`Target ${content.target}`);
        }
        if (content.action === 'on') {
          target.addEventListener(
            content.type,
            ...resolveEventListenerAndOptions(content),
          );
        } else {
          target.removeEventListener(
            content.type,
            ...resolveEventListenerAndOptions(content),
          );
        }
        break;
      }
      case 'observer': {
        switch (content.type) {
          case 'intersection': {
            handleIntersectionObserver(context, worker, content);
            break;
          }
          case 'resize': {
            handleResizeObserver(context, worker, content);
            break;
          }
        }
        break;
      }
      case 'error': {
        console.error(prefix, content);
        break;
      }
      default: {
        console.warn(prefix, event.data);
        break;
      }
    }
  }

  worker.addEventListener('message', messageHandler);
  worker.addEventListener('error', errorHandler);
  worker.addEventListener('messageerror', errorHandler);

  const offscreenCanvas = canvas.transferControlToOffscreen();
  const { offsetLeft, offsetTop, clientWidth, clientHeight } = canvas;
  worker.postMessage(
    {
      type: 'transfer',
      content: {
        props: { shadows: true, frameloop: 'demand' },
        offscreenCanvas,
        pixelRatio: Math.min(Math.max(1, window.devicePixelRatio), 2),
        windowSize: measureWindowSize(),
        windowScroll: measureWindowScroll(),
        trackedRects: measureSerializedBoundingClientRects(trackedElements),
        canvasSize: {
          left: offsetLeft,
          top: offsetTop,
          width: clientWidth,
          height: clientHeight,
          updateStyle: false,
        },
      },
    } satisfies Message<'transfer', TransferContent>,
    [offscreenCanvas],
  );

  return () => {
    worker.terminate();
    for (const [, observer] of intersectionObservers) {
      observer.disconnect();
    }
    intersectionObservers.clear();
    for (const [, observer] of resizeObservers) {
      observer.disconnect();
    }
    resizeObservers.clear();
    worker.removeEventListener('message', messageHandler);
    worker.removeEventListener('messageerror', errorHandler);
    worker.removeEventListener('error', errorHandler);
  };
}

function hydrateObserverTarget(context: SerializableContext, target: string) {
  switch (target) {
    case ':root': {
      return document.documentElement;
    }
    case 'canvas': {
      return context.canvas;
    }
  }
  if (target in context.trackedElements) {
    const element = document.querySelector(target);
    if (element) {
      return element;
    }
  }
  throw new UnsupportedError(`Target ${target}`);
}

function hydrateEventTarget(context: SerializableContext, target: string) {
  switch (target) {
    case 'window': {
      return window;
    }
    case 'document': {
      return document;
    }
  }
  return hydrateObserverTarget(context, target);
}

function handleIntersectionObserver(
  context: SerializableContext,
  worker: Worker,
  {
    type,
    id,
    ...content
  }:
    | IntersectionObserverLifecycleSubscription
    | IntersectionObserverSubscription,
) {
  const intersectionObserver = intersectionObservers.get(id);
  switch (content.action) {
    case 'on': {
      if (intersectionObserver) {
        throw new Error(
          `Observer of type '${type}' and id '${id}' is already created`,
        );
      }
      intersectionObservers.set(
        id,
        new IntersectionObserver(
          (entries) =>
            worker.postMessage({
              type: 'observer',
              content: {
                type,
                id,
                entries: entries.map((entry) =>
                  serializeIntersectionObserverEntry(context, entry),
                ),
              },
            } satisfies Message<'observer', IntersectionObserverUpdate>),
          content.options,
        ),
      );
      break;
    }
    case 'off': {
      if (!intersectionObserver) {
        throw new Error(`IntersectionObserver '${id}' does not exist`);
      }
      intersectionObservers.delete(id);
      intersectionObserver.disconnect();
      break;
    }
    case 'observe': {
      if (!intersectionObserver) {
        throw new Error(`IntersectionObserver '${id}' does not exist`);
      }
      intersectionObserver.observe(
        hydrateObserverTarget(context, content.target),
      );
      break;
    }
    case 'unobserve': {
      if (!intersectionObserver) {
        throw new Error(`IntersectionObserver '${id}' does not exist`);
      }
      intersectionObserver.unobserve(
        hydrateObserverTarget(context, content.target),
      );
      break;
    }
  }
}

function handleResizeObserver(
  context: SerializableContext,
  worker: Worker,
  {
    type,
    id,
    ...content
  }: ResizeObserverLifecycleSubscription | ResizeObserverSubscription,
) {
  const resizeObserver = resizeObservers.get(id);
  switch (content.action) {
    case 'on': {
      if (resizeObserver) {
        throw new Error(
          `Observer of type '${type}' and id '${id}' is already created`,
        );
      }
      resizeObservers.set(
        id,
        new ResizeObserver((entries) =>
          worker.postMessage({
            type: 'observer',
            content: {
              type,
              id,
              entries: entries.map((entry) =>
                serializeResizeObserverEntry(context, entry),
              ),
            },
          } satisfies Message<'observer', ResizeObserverUpdate>),
        ),
      );
      break;
    }
    case 'off': {
      if (!resizeObserver) {
        throw new Error(
          `Observer of type '${type}' and id '${id}' does not exist`,
        );
      }
      resizeObservers.delete(id);
      resizeObserver.disconnect();
      break;
    }
    case 'observe': {
      if (!resizeObserver) {
        throw new Error(
          `Observer of type '${type}' and id '${id}' does not exist`,
        );
      }
      resizeObserver.observe(
        hydrateObserverTarget(context, content.target),
        content.options,
      );
      break;
    }
    case 'unobserve': {
      if (!resizeObserver) {
        throw new Error(
          `Observer of type '${type}' and id '${id}' does not exist`,
        );
      }
      resizeObserver.unobserve(hydrateObserverTarget(context, content.target));
      break;
    }
  }
}

function serializeMouseEventData(event: MouseEvent) {
  return {
    x: event.x,
    y: event.y,
    altKey: event.altKey,
    button: event.button,
    buttons: event.buttons,
    clientX: event.clientX,
    clientY: event.clientY,
    ctrlKey: event.ctrlKey,
    metaKey: event.metaKey,
    movementX: event.movementX,
    movementY: event.movementY,
    offsetX: event.offsetX,
    offsetY: event.offsetY,
    pageX: event.pageX,
    pageY: event.pageY,
    screenX: event.screenX,
    screenY: event.screenY,
    shiftKey: event.shiftKey,
  } satisfies Omit<
    SerializedMouseEvent,
    | keyof HydratedEventInit
    | 'isTrusted'
    | 'eventPhase'
    | 'defaultPrevented'
    | 'type'
    | 'timeStamp'
    | 'detail'
  >;
}

function serializeEvent(
  context: SerializableContext,
  event: PointerEvent | WheelEvent | MouseEvent | Event,
) {
  const target = serializeNullableEventTargetInMainThread(
    context,
    event.target,
  );
  const currentTarget = serializeEventTargetInMainThread(
    context,
    event.currentTarget,
  );
  const common = {
    bubbles: event.bubbles,
    cancelable: event.cancelable,
    composed: event.composed,
    defaultPrevented: event.defaultPrevented,
    eventPhase: event.eventPhase,
    timeStamp: event.timeStamp,
    target,
    currentTarget,
  };
  switch (event.type) {
    case 'pointerdown':
    case 'pointerup':
    case 'pointermove':
    case 'pointerover':
    case 'pointerout':
    case 'pointerenter':
    case 'pointerleave':
    case 'gotpointercapture':
    case 'lostpointercapture': {
      const e = event as PointerEvent;
      return {
        ...common,
        ...serializeMouseEventData(e),
        type: event.type,
        pointerId: e.pointerId,
        width: e.width,
        height: e.height,
        pressure: e.pressure,
        tangentialPressure: e.tangentialPressure,
        tiltX: e.tiltX,
        tiltY: e.tiltY,
        twist: e.twist,
        pointerType: e.pointerType,
        isPrimary: e.isPrimary,
      } satisfies SerializedPointerEvent;
    }
    case 'wheel': {
      const e = event as WheelEvent;
      return {
        ...common,
        ...serializeMouseEventData(e),
        type: event.type,
        deltaX: e.deltaX,
        deltaY: e.deltaY,
        deltaZ: e.deltaZ,
        deltaMode: e.deltaMode,
      } satisfies SerializedWheelEvent;
    }
    case 'click':
    case 'dblclick':
    case 'contextmenu':
    case 'mousedown':
    case 'mouseup':
    case 'mouseover':
    case 'mouseout':
    case 'mousemove': {
      const e = event as MouseEvent;
      return {
        ...common,
        ...serializeMouseEventData(e),
        type: event.type,
      } satisfies SerializedMouseEvent;
    }
    case 'resize': {
      const [width, height] = measureWindowSize();
      return {
        ...common,
        type: event.type,
        width,
        height,
      } satisfies SerializedResizeEvent;
    }
    case 'scroll': {
      const [scrollX, scrollY] = measureWindowScroll();
      return {
        ...common,
        type: event.type,
        scrollX,
        scrollY,
        trackedRects: measureSerializedBoundingClientRects(
          context.trackedElements,
        ),
      } satisfies SerializedScrollEvent;
    }
    case 'visibilitychange': {
      return {
        ...common,
        type: event.type,
        visibilityState: document.visibilityState,
      } satisfies SerializedVisibilityChangeEvent;
    }
  }
  throw new UnsupportedError(`Event type '${event.type}'`);
}

function serializeIntersectionObserverEntry(
  context: SerializableContext,
  entry: IntersectionObserverEntry,
) {
  return {
    boundingClientRect: entry.boundingClientRect.toJSON() as SerializedDOMRect,
    intersectionRect: entry.intersectionRect.toJSON() as SerializedDOMRect,
    rootBounds: entry.rootBounds?.toJSON(),
    target: serializeObserverTargetInMainThread(context, entry.target),
    intersectionRatio: entry.intersectionRatio,
    isIntersecting: entry.isIntersecting,
    time: entry.time,
  };
}

function serializeResizeObserverSize(box: ResizeObserverSize) {
  return { blockSize: box.blockSize, inlineSize: box.inlineSize };
}

function serializeResizeObserverEntry(
  context: SerializableContext,
  entry: ResizeObserverEntry,
) {
  return {
    contentRect: entry.contentRect.toJSON() as SerializedDOMRect,
    target: serializeObserverTargetInMainThread(context, entry.target),
    borderBoxSize: entry.borderBoxSize.map((s) =>
      serializeResizeObserverSize(s),
    ),
    contentBoxSize: entry.contentBoxSize.map((s) =>
      serializeResizeObserverSize(s),
    ),
    devicePixelContentBoxSize: entry.devicePixelContentBoxSize.map((s) =>
      serializeResizeObserverSize(s),
    ),
  };
}
