import { type Bone, type Material, type Mesh } from 'three';
// @ts-expect-error: TS7016 because examples libs are not typed
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
// @ts-expect-error: TS7016 because examples libs are not typed
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader';
// @ts-expect-error: TS7016 because examples libs are not typed
import { MeshoptDecoder } from 'three/examples/jsm/libs/meshopt_decoder.module';
import { type LoaderProto, useLoader } from '@react-three/fiber';

// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-call
const dracoLoader: DRACOLoader = new DRACOLoader();
// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-call
dracoLoader.setDecoderPath('/draco/');

function extensions(loader: GLTFLoader) {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-call
  loader.setDRACOLoader(dracoLoader);
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-call
  loader.setMeshoptDecoder(MeshoptDecoder);
}

export function useGLTF<
  M extends {
    nodes?: Record<string, Mesh | Bone>;
    materials?: Record<string, Material>;
  },
  T extends string | string[],
>(path: T) {
  return useLoader<M, T, LoaderProto<M>>(
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    GLTFLoader,
    path,
    extensions,
  ) as T extends unknown[] ? M[] : M;
}

useGLTF.preload = function (path: string | string[]) {
  useLoader.preload(GLTFLoader, path, extensions);
};

useGLTF.clear = function (input: string | string[]) {
  useLoader.clear(GLTFLoader, input);
};
