import { type PrimitiveProps } from '@react-three/fiber';
import { type AppleWatchModel, GLTFModel } from '@billykwok/3d';

import { AnimatedPrimitive } from '@/utils/react-three-fiber/animations';
import { useGLTF } from '@/utils/react-three-fiber/useGLTF';

const modelPath = `/models/${GLTFModel.AppleWatch}`;

export function AnimatedAppleWatch(props: Partial<PrimitiveProps>) {
  const { scene } = useGLTF<AppleWatchModel, string>(modelPath);
  return <AnimatedPrimitive object={scene} dispose={null} {...props} />;
}

useGLTF.preload(modelPath);
