import {
  type ReactNode,
  createContext,
  useContext,
  useMemo,
  useCallback,
  useEffect,
} from 'react';
import { type SpringValues, SpringValue, useSpring } from '@react-spring/core';

import { useEventListener } from '@/components/react-three-fiber/hooks/useEventListener';
import { clamp } from '@/utils/maths';
import { useEventEffect } from 'use-recapture';

export type ScrollTimelineSpringState = Readonly<{ xy: [number, number] }>;

export type ScrollTimelineSpring = SpringValues<ScrollTimelineSpringState>;

const ScrollTimelineSpringContext = createContext<ScrollTimelineSpring>({
  xy: new SpringValue(),
});

export type ScrollTimelineSpringProviderProps = { children: ReactNode };

export function ScrollTimelineSpringProvider({
  children,
}: ScrollTimelineSpringProviderProps) {
  const element = useMemo(() => {
    const el = document.querySelector('#sticky-parent');
    if (!el) {
      throw new Error('Sticky parent not found');
    }
    return el;
  }, []);
  const measureScrollTimeline = useCallback(
    (el: Element): ScrollTimelineSpringState => {
      const { innerWidth: W, innerHeight: H } = window;
      const { x, y, width: w, height: h } = el.getBoundingClientRect();
      const rx = w - W;
      const ry = h - H;
      return {
        xy: [rx ? clamp(-x, 0, rx) / rx : 0, ry ? clamp(-y, 0, ry) / ry : 0],
      };
    },
    [],
  );
  const [spring, api] = useSpring(() => measureScrollTimeline(element));
  useEventListener(
    document,
    'scroll',
    () => {
      const xy = measureScrollTimeline(element);
      console.log('scrollY', xy.xy[1]);
      for (const listener of listeners) {
        listener(xy);
      }
      api.start(xy);
    },
    { passive: true },
  );
  return (
    <ScrollTimelineSpringContext.Provider value={spring}>
      {children}
    </ScrollTimelineSpringContext.Provider>
  );
}

export function useScrollTimelineSpringContext(): ScrollTimelineSpring {
  return useContext(ScrollTimelineSpringContext);
}

const listeners = new Set<(state: ScrollTimelineSpringState) => void>();

export function useScrollTimelineListener(
  listener: (state: ScrollTimelineSpringState) => void,
) {
  const _listener = useEventEffect(listener);
  useEffect(() => {
    listeners.add(_listener);
    return () => {
      listeners.delete(_listener);
    };
  });
}
