import {
  type Node,
  type ReconcilerRoot,
  addEffect,
  extend,
} from '@react-three/fiber';
import { Globals } from '@react-spring/core';
import { createStringInterpolator, colors, raf } from '@react-spring/shared';
import {
  ColorManagement,
  Group,
  Sphere,
  BoxGeometry,
  SphereGeometry,
  Mesh,
  MeshBasicMaterial,
  MeshStandardMaterial,
  OrthographicCamera,
  PerspectiveCamera,
  PointLight,
  SpotLight,
  AmbientLight,
  Color,
  Fog,
} from 'three';
import { RoundedPlaneGeometry } from 'maath/geometry';

export type ReactThreeFiberApp = (
  root: ReconcilerRoot<HTMLCanvasElement | OffscreenCanvas>,
  canvas: HTMLCanvasElement | OffscreenCanvas,
) => () => void;

declare global {
  namespace JSX {
    interface IntrinsicElements {
      roundedPlaneGeometry: Node<
        RoundedPlaneGeometry,
        typeof RoundedPlaneGeometry
      >;
    }
  }
}

export function setUpReactThreeFiber() {
  ColorManagement.enabled = true;
  Globals.assign({ createStringInterpolator, colors, frameLoop: 'demand' });

  // Let @react-spring/shared drive the frameloop.
  addEffect(() => raf.advance());

  extend({
    Group,
    Sphere,
    SphereGeometry,
    Mesh,
    MeshBasicMaterial,
    MeshStandardMaterial,
    OrthographicCamera,
    PerspectiveCamera,
    PointLight,
    SpotLight,
    AmbientLight,
    Color,
    Fog,
    BoxGeometry,
    RoundedPlaneGeometry,
  });
}
